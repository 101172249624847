import { Injectable } from '@angular/core';
import { EMPTY, expand, filter, map, Observable, switchMap, toArray } from 'rxjs';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import {
  AddNotificationRecipient,
  GetNotificationConfigRequest,
  NotificationConfigModel,
  NotificationRecipientModel,
  NotificationConfigService,
  CursorPaginatedResultNotificationConfigModel,
  UpdateNotificationConfigRequest,
  NotificationType,
} from '@dpdhl-iot/api/backend';
import { CoreConstants, NotificationMapperService, NotificationViewModel } from '@dpdhl-iot/shared';
import { IotApplicationModel } from '@dpdhl-iot/api/management';

@Injectable({
  providedIn: 'root',
})
export class NotificationManagementService {
  private application: IotApplicationModel;

  constructor(
    private notificationConfigService: NotificationConfigService,
    private applicationDataService: ApplicationDataService,
    private notificationMapperService: NotificationMapperService,
  ) {
    this.applicationDataService.application$
      .pipe(
        filter((a) => !!a.application),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((app) => (this.application = app));
  }

  getNotifications(notificationType: NotificationType): Observable<NotificationViewModel[]> {
    const getNotificationRequest: GetNotificationConfigRequest = {
      filters: [],
      pageSize: 1000,
      searchValue: '',
      sortColumnName: '',
      sortOrder: 'asc',
    };

    return this.getNotificationRecursive(getNotificationRequest, notificationType);
  }

  getUserAreasNotificationConfig(areaId: string): Observable<NotificationConfigModel> {
    return this.notificationConfigService.getUserAreasNotificationConfigAsync(
      this.application.uuid,
      areaId,
      CoreConstants.API_VERSION,
    );
  }

  updateNotificationConfig(
    areaId: string,
    request: UpdateNotificationConfigRequest,
    deviceId?: string,
  ): Observable<boolean> {
    return this.notificationConfigService.updateNotificationConfigAsync(
      this.application.uuid,
      areaId,
      CoreConstants.API_VERSION,
      deviceId,
      request,
    );
  }

  addNotificationRecipient(
    areaId: string,
    recipients: AddNotificationRecipient[],
    deviceId?: string,
  ): Observable<NotificationRecipientModel[]> {
    return this.notificationConfigService.addAreaNotificationRecipientsAsync(
      this.application.uuid,
      areaId,
      CoreConstants.API_VERSION,
      deviceId,
      recipients,
    );
  }

  deleteNotificationRecipient(
    areaId: string,
    recipientId: string,
    deviceId?: string,
  ): Observable<boolean> {
    return this.notificationConfigService.deleteNotificationRecipientAsync(
      this.application.uuid,
      areaId,
      recipientId,
      CoreConstants.API_VERSION,
      deviceId,
    );
  }

  updateNotificationConfigAndRecipients(
    notificationConfigId: string,
    areaId: string,
    notifyOnClose: boolean,
    deviceId?: string,
  ): Observable<boolean> {
    if (notificationConfigId) {
      return this.updateNotificationConfig(
        areaId,
        {
          notifyOnClose,
        },
        deviceId,
      );
    } else {
      return this.addNotificationRecipient(areaId, [], deviceId).pipe(
        switchMap(() =>
          this.updateNotificationConfig(
            areaId,
            {
              notifyOnClose,
            },
            deviceId,
          ),
        ),
      );
    }
  }

  private getNotificationRecursive(
    getNotificationRequest: GetNotificationConfigRequest,
    notificationType: NotificationType,
  ): Observable<NotificationViewModel[]> {
    return this.fetchNotification(getNotificationRequest, notificationType)
      .pipe(
        expand((result) => {
          if (result.next) {
            getNotificationRequest.cursor = result.next;
            return this.fetchNotification(getNotificationRequest, notificationType);
          } else {
            return EMPTY;
          }
        }),
        // accumulate all the data into a single array
        toArray(),
        // map to extract only the data from pagination source
        map((allData) => allData.flatMap((data) => data.items)),
      )
      .pipe((result) => this.notificationPagedViewModelPipe(result));
  }

  private fetchNotification(
    getNotificationRequest: GetNotificationConfigRequest,
    notificationType: NotificationType,
  ): Observable<CursorPaginatedResultNotificationConfigModel> {
    return this.notificationConfigService.getAreasNotificationConfigAsync(
      this.application.uuid,
      CoreConstants.API_VERSION,
      notificationType,
      getNotificationRequest,
    );
  }

  private notificationPagedViewModelPipe(
    notificationObservable: Observable<NotificationConfigModel[]>,
  ): Observable<NotificationViewModel[]> {
    return notificationObservable.pipe(
      map((cursor) =>
        cursor.map((item) => this.notificationMapperService.toNotificationViewModel(item)),
      ),
      map((data) => data.sort((a, b) => a.recipients.length - b.recipients.length)),
    );
  }
}
